$('.toggle-show').click(function(){
    var elem = $('.' + $(this).data('show'));

    elem.toggleClass('show');
});

$('.remove-show').click(function(){
    var elem = $('.' + $(this).data('show'));

    elem.removeClass('show');
});

$('.add-show').click(function(){
    var elem = $('.' + $(this).data('show'));

    elem.addClass('show');
});

var groups = [];

$('.outside-hide').each(function(){
    var group = $(this).data('group'),
        elems = $('[data-group="' + group + '"]'),
        elem = $('.' + $(this).data('show'));

    if($.inArray( group, groups ) == '-1'){
        groups.push(group);

        $(document).click(function (e){
            if (!elems.is(e.target) && elems.has(e.target).length === 0) { // и не по его дочерним элементам
                elem.removeClass('show');
            }
        });
    }

});
$('#wu_send').click(function(e){
    e.preventDefault();
    var form    = $('.wu_form'),
        wrapper = form.find('.left'),
        phone   = $.trim($('#wu_phone').val()),
        name    = $.trim($('#wu_name').val()),
        message = $.trim($('#wu_message').val()),
        success = form.find('.success-screen'),
        data    = {
            'user_name' : name,
            'phone'     : phone,
            'message'   : message,
            'method'    : 1
        };

    form.find('.required').each(inputCheck);

    if(form.find('.error').length == 0){
        sendAjax('add_order', data, function(res){
            success.show();
            resetInput(form.find('.required'));
            wrapper.addClass('show-success');
        }, function(res){
            resultPopup('error', 'Заявка', 'Ошибка', 'Во время отправки вашей заявки произошла ошибка, попробуйте чуть позже');
        });
    }

});
$.Tween.propHooks.number = {
    get: function ( tween ){
        var num = tween.elem.innerHTML.replace(/&nbsp;/gi, '');
        tween.start = parseFloat(num);
        return  parseFloat(num) || 0;
    },
    
	set: function( tween ) {
        var opts = tween.options;
        tween.elem.innerHTML = (opts.prefix || '')
        + tween.now.toFixed(opts.fixed || 0)
        + (opts.postfix || '');
        tween.elem.innerHTML = parseInt(tween.elem.innerHTML).toLocaleString();
	}
};
function headerScroll(){
    if($(window).scrollTop()){
        $('header.site-header').addClass('scroll');
    }else{
        $('header.site-header').removeClass('scroll');
    }
}

headerScroll();

$(window).scroll(headerScroll);

function scrolling(){
    var items = $('.do-animation'),
        scroll = $(window).scrollTop();

    if(items.length > 0){
        items.each(function(){
            var elem = $(this),
                height = $(window).height() * 0.7,
                offset = elem.offset().top - height;

            if(offset <= scroll){
                elem.removeClass('do-animation');
                elem.addClass('show');
            }

        })
    }
    
}

scrolling();
$(window).scroll(scrolling);

window.onload = function(){
    var h = $('.first-slide').height(),
        w = $('.first-slide').width(),
        size = Math.max(h,w) * 3;

    document.documentElement.style.cssText = "--slide-size: " + size + "px";
};
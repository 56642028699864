$(document).ready(function () {

    $(document).on('click', '.select-dropdown .item', function(){
        var elem     = $(this),
            id       = elem.data('id'),
            items    = elem.siblings(),
            wrapper  = elem.parents('.input-wrapper'),
            parent   = elem.parents('.form-group'),
            input    = parent.find('input'),
            dropdown = parent.find('.select-dropdown');        
        
        items.removeClass('selected-item');
        elem.addClass('selected-item');
        input.addClass('selected');
        input.val(elem.text());
        input.attr('data-id', id);
        parent.removeClass('error');
        wrapper.removeClass('focus');
        dropdown.stop();
        dropdown.fadeOut(200);
    });

    $(document).on('click', '.input-wrapper.select', function(e){
        var elem     = $(this),
            parent   = elem.parent(),
            input    = elem.find('input'),
            dropdown = parent.find('.select-dropdown');

        if(input.is(e.target)){
            elem.toggleClass('focus');
            dropdown.stop();
            dropdown.fadeToggle(200);
            parent.removeClass('error');
    
            $(document).click(function (e){
                if (!elem.is(e.target) && elem.has(e.target).length === 0) {
                    elem.removeClass('focus');
                    dropdown.stop();
                    dropdown.fadeOut(200);
                }
            });
        }

    });

    $('#test-btn').click(function(){
        $('.services-content .required').each(inputCheck);
    });

    $(document).on('click', '.whatsapp-toggler', function(){
        $(this).toggleClass('active');
    })

    $(document).on('click', '.add-input', function(){        
        var btn         = $(this),
            wrapper     = btn.parents('.form-group'),
            input       = wrapper.find('input'),
            placeholder = input.attr('placeholder'),
            id          = input.data('add-id'),
            mask        = input.data('mask'),
            label       = input.data('add-label'),
            whatsapp    = wrapper.find('.whatsapp-toggler').length;

        if($('#' + id).length == 0){
            var width       = '',
                mask_class  = '',
                structure   = '';

            btn.remove();

            if(wrapper.hasClass('half')){
                wrapper.removeClass('half').addClass('fourth');
                width = 'fourth';
            }else{
                wrapper.addClass('half');
                width = 'half';
            }

            if(mask){
                mask_class = mask + '-mask';
            }

            if(whatsapp){
                structure = '<div class="form-group ' + width + '"><label for="' + label + '">' + label + '</label><div class="input-wrapper"><div class="input-btns"><div class="icon-whatsapp whatsapp-toggler"></div></div><input type="text" id="' + id + '" class="' + mask_class + '" placeholder="' + placeholder + '"></div></div>';
            }else{
                structure = '<div class="form-group ' + width + '"><label for="' + label + '">' + label + '</label><input type="text" id="' + id + '" class="' + mask_class + '" placeholder="' + placeholder + '"></div>';
            }

            wrapper.after(structure);

            if(mask){
                eval(mask + '_mask()');
            }

        }
    })

});
var credit_sum  = 100000,
    credit_date = 3;

function calc(){
    var sum = credit_sum / credit_date,
        percents = credit_sum * 0.02,
        sum = Math.round(sum + percents),
        price = $('.calc-price');

    price.stop();

    price
        .delay(0)
        .animate({ number: sum }, {
            duration: 500,
        });
}

calc();

$( "#credit-sum" ).slider({
    range: "min",
    value: 100000,
    step: 100000,
    min: 100000,
    max: 10000000,
    slide: function( event, ui ) {
        credit_sum = ui.value;
        $( "#credit-sum-res span" ).text( ui.value.toLocaleString() );
        calc();
    }
});

$( "#credit-date" ).slider({
    range: "min",
    value: 3,
    step: 3,
    min: 3,
    max: 120,
    slide: function( event, ui ) {
        credit_date = ui.value;
        $( "#credit-date-res span" ).text( ui.value );
        calc();
    }
});